<template>
	<div class="excel-source webhook-source">
		<!--button type="button" class="download-btn" v-on:click="download">Download</button-->
		
		<div class="stepper-md mb-5 pb-5">
			<div class="mdl-card mdl-shadow--2dp">
				<div class="mdl-card__supporting-text">
					<div class="mdl-stepper-horizontal-alternative">
						<div v-for="(item, key) in this.tabsPanel"
							:class="['mdl-stepper-step', item.selected ? 'active-step' : '', item.finished ? 'step-done' : '' ]" :key="`tab-${key}`">
							<div class="mdl-stepper-circle excel-circle"><span>{{ item.id }}</span></div>
							<div class="mdl-stepper-title"> {{ $t(item.name) }} </div>
							<div class="mdl-stepper-bar-left"></div>
							<div class="mdl-stepper-bar-right"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="row" v-show="this.tabsPanel[0].selected && !this.tabsPanel[0].finished" :key="`A-${1}`">
				
				<div class="col-md-7 mx-auto">
					<div class="exel-etape-2 mt-4">
						<div class="row">
							<div class="col-md-12">
								<div :class="['form-group row', validate ? 'has-error' : '']">
									<div class="col-md-12 my-auto">
										<label for="sourceName" class="m-0 font-weight-bold">{{ $t('NameYourSource') }}</label>
									</div>
									<div class="col-md-12">
										<input id="sourceName" type="text" class="form-control" :placeholder="$t('Yoursourcenamehere')" v-model="sourceName" />
										<small v-if="validate" class="help-block">{{ $t('NameSourceTitle') }}</small>
									</div>
								</div>
								
								<div class="row pt-2 pb-5">
									<div class="col-md-12 text-right">
										<button class="btn btn-upgrade" @click="SaveSource" style="height: 46px;">
											<i :class="['fa', loadingProc ? 'fa-spinner fa-spin' : 'fa-cogs']"></i> {{$t('CreateYourSource')}}
										</button>
									</div>
								</div>
								
							</div>
						</div>
					</div>
					
				</div>
				
			</div>
			<div class="row" v-show="this.tabsPanel[1].selected && !this.tabsPanel[1].finished" :key="`A-${2}`">
				<div class="col-md-7 mx-auto">
					
					<div class="row">
						<div class="col-md-12 mb-3">
							<div class="font-weight-bold mb-2">{{$t('Link')}}</div>
							<div class="form-inline">
								<div class="form-group mb-2" style="width: 82%;">
									<input type="text" style="width: 98%;" class="form-control" v-model="ApiLink" placeholder="Link" />
								</div>
								<button type="submit" class="btn btn-secondary mb-2" @click="CopyText">{{$t('Copylink')}}</button>
							</div>
						</div>
						<div class="col-md-12 mb-3">
							<div class="font-weight-bold mb-2">{{$t('Method')}}</div>
							<div><span class="alert alert-secondary text-danger rounded-0 py-0 px-2">POST</span></div>
						</div>
						<div class="col-md-12 mb-3">
							<div class="font-weight-bold mb-2">{{ $t('Input')}}</div>
							
							<div style="font-size: 14px;" v-html="$t('InputMapping')">
								<!--div><b>Use the URL above to create a webhook integration on your favorite lead generation tool, then, map the fields of your lead generation form to the following OhMyLead fields:</b></div>
								<div>first_name (optional)</div>
								<div>last_name (optional)</div>
								<div>full_name (optional)</div>
								<div>email (optional)</div>
								<div class="mb-3">phone (optional)</div>
								<div class="mb-3">Any additional fields will be saved to Ohmylead as custom fields.</div-->
								
							</div>
							
							<!--div class="table-grid">
								<table class="table-md">
									<tbody>
										<tr>
											<td><b>Key</b><br>(string)</td>
											<td><b>Authentication key</b><br>(optional if sent from an authorized domain)</td>
										</tr>
										<tr>
											<td><b>email</b><br>(string)</td>
											<td><b>An email address</b></td>
										</tr>
									</tbody>
								</table>
							</div-->
						</div>
						<div class="col-md-12 text-right">
							<router-link :to="{ path : '/newsource' }" class="btn btn-primary">
								<i class="fa fa-cogs"></i> <span>{{$t('Finish')}}</span>
							</router-link>
						</div>
					</div>
					
				</div>
			</div>
		</div>
		
	</div>
</template>
<script>
	export default {
		name:'WebhookApi',
		data() {
			return {
				visibleCollapse	: [ true ],
				
				notificationArray: {
					active		: false,
					type 		: 'notification',
					// subject		: '',
					// from_email	: '',
					// from_name	: '',
					recipients	: [],
					// content		: '',
				},
				confirmationArray: {
					active		: false,
					type		: 'confirmation',
					from_email	: '',
					from_name	: '',
					subject		: '',
					content		: '',
					
					sender_email: '',
					sender_name : '',
				},
				loadingProc		: false,
				
				
				tabsPanel	: [
					{ id:1, name: 'SourceName',	selected: true,  finished: false },
					{ id:2, name: 'Finish', 	selected: false, finished: false },
				],
				
				validate	: false,
				
				sourceName	: '',
				fieldsFile	:[],
				dataArray	:[],
				ApiLink		: '',
				Provider	: ''
			}
		},
		methods: {
			CopyText() {
				let that = this
				try {
					navigator.clipboard.writeText(that.ApiLink)
				} catch (err) {
					console.error('Failed !');
				}
			},
			stepPrevious(element) {
				
				this.tabsPanel.forEach( function( el, key ) {
					if( element < key ) {
						el.selected = false
						el.finished = false
					}
				} );
				
				this.tabsPanel[element].selected = true;
				this.tabsPanel[element].finished = false;
				
			},
			
			stepNext(element) {
				
				this.tabsPanel.forEach( function( el ) {
					
					if( element > el.id ) {
						el.selected = true
						el.finished = true
					} else if( element == el.id ) {
						el.selected = true;
						el.finished = false;
					} else {
						el.selected = false
						el.finished = false
					}
					
				} );
				
			},
			
			SaveSource() {
				let that = this
				that.validate = false
				
				if ( that.sourceName != '' ) {
					
					that.loadingProc = true
					let url		= 'webhook/generate'
					let method	= 'POST'
					let data	= {
						name: that.sourceName,
						parameters: "webhook source",
						providers: that.Provider, //"WebHook",
						active: true,
						confirmation: that.confirmationArray,
						notification: that.notificationArray
					}
					
					that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
						console.log(response.data)
						window.gist.track( 'Sent Project Data', {
								// project
								projectId: response.data.source_id,
								projectSlug: 'Webhook',
								projectCollaborators: 1,
								// owner
								ownerId: '',
								ownerType: 'company',
								ownerOwners: 14,
								// usage
								chatsMonthly: 4811,
								callsWeekly: 22
								});
						that.loadingProc = false
						that.stepNext(2)
						that.ApiLink = response.data.link
						// that.$router.push({ name: "myLeads" })
						that.leftSidebarInfo()
					}).catch(() => {
						that.loadingProc = false
						// that.$router.push({ name: "myLeads" })
					})
					
				} else {
					that.validate = true
				}
				
			},
			
			leftSidebarInfo() {
				let that = this
				let url   = 'left_sidebar'
				let method= 'GET'
				let data  = {}
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					console.log(response)
				}).catch(( {response} ) => {
					console.log(response)
				})
			},
			
			
			loadDefaultLeadAlert() {
				
				let that = this
				
				let url   = 'lead_alert'
				let method= 'GET'
				let data  = {}
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					// that.notificationArray = response.data;
					that.notificationArray.active		= response.data.active
					that.notificationArray.type 		= response.data.type
					// that.notificationArray.subject		= response.data.subject
					// that.notificationArray.from_email	= response.data.from_email
					// that.notificationArray.from_name	= response.data.from_name
					that.notificationArray.recipients	= response.data.recipients
					// that.notificationArray.content		= response.data.content
					
				}).catch((  ) => {
				})
				
            },
			
			loadDefaultWelcomeEmail() {
                
				let that = this
				
				let url   = 'welcome_email'
				let method= 'GET'
				let data  = {}
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					
					// that.confirmationArray = response.data;
					let content = response.data.content.toString()
					that.confirmationArray.active		= response.data.active
					that.confirmationArray.type 		= response.data.type
					that.confirmationArray.subject		= response.data.subject
					that.confirmationArray.from_email	= response.data.from_email
					that.confirmationArray.from_name	= response.data.from_name
					that.confirmationArray.content		= content
					
					that.confirmationArray.sender_email	= response.data.sender_email
					that.confirmationArray.sender_name	= response.data.sender_name
					
				}).catch((  ) => {
				})
				
            },
			
		},
		mounted() {
			let that = this
			that.Provider = that.$route.params.source
			// console.log(that.Provider)
			that.loadDefaultLeadAlert()
			that.loadDefaultWelcomeEmail()
		}
	}
</script>
<style lang="scss">
    
	.webhook-source {
		.stepper-md .mdl-stepper-horizontal-alternative .mdl-stepper-step {
			right: 70px;
		}
	}
	
</style>